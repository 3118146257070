import React, {useState, useEffect} from 'react';
import First from '../../../components/editorial/First';
import Gallery from '../../../components/editorial/Gallery';
import ChessText from '../../../components/editorial/ChessText';
import CarouselVideo from '../../../components/editorial/CarouselVideo';
import Legal from '../../../components/editorial/Legal';
import {graphql} from 'gatsby';
import useLS from '../../../hooks/useLS';
import Layout from '../../../UI/Layout';
import HeaderData from '../../../../queries/data/header/header-glass';



const Editorial = (props) => {
    const [selected, setSelected] = useState(0);

    const {data, uri} = props;

    const LSData = useLS();

    const homeData = data.wp.pages.nodes.filter((itm) => itm.databaseId === 2);
    const tv_gallery = homeData[0]?.sky?.infoFlux?.tv;
    const first = homeData[0]?.sky?.infoFlux;
    const chessText = homeData[0]?.sky?.infoFlux?.chessTxt;
    const carousel = homeData[0]?.sky?.infoFlux?.carousel;
    const legal = homeData[0]?.sky?.infoFlux?.legal;

    console.log('homeData', homeData);

    const dataHeader = HeaderData.data.wp.tv.dataHeader;

    let content = (
        <Layout uri={uri} dataHeader={dataHeader}>
            <section className="Editorial">
                <div className="afterHeader">
                    <CarouselVideo withLoader noTitle info={carousel}/>
                    <First info={first}/>
                    <Gallery storeID={LSData.storeID} withButton info={tv_gallery} selected={selected} setSelected={setSelected}/>
                    <ChessText info={chessText}/>
                    <Legal legal={legal}/>
                </div>
            </section>
        </Layout>
    )

    return LSData.login ? content : '';
}

export default Editorial;


export const query = graphql`{
  wp {
    pages {
      nodes {
        title
        databaseId
        sky {
          infoFlux {
            tv {
              colorBlocks {
                  gallery {
                      mediaItemUrl
                      slug
                  }
                colorTitle
                hexColor
              }
              mainText
              mainImage {
                mediaItemUrl
              }
            }
            chessTxt {
              mainText
              chessBoard {
                text
              }
            }
            legal {
              mainText
            }
            carousel {
              mainCarousel {
                image {
                  mediaItemUrl
                }
                vimeoId
                text
              }
            }
          }
        }
      }
    }
  }
}`;