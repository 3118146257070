import React from 'react';

const First = (props) => {
    
    return (
        <div className="First FirstSection">
            <div className="img">
                <img src={props?.info?.tv?.mainImage?.mediaItemUrl} />
            </div>
            <div className="text">
                <div dangerouslySetInnerHTML={{__html: props?.info?.tv?.mainText}}></div>
            </div>
        </div>
    )
}

export default First;