import React from 'react';

const ChessText = ({info}) => {
    return (
        <div className="ChessText">
            <div className="txt">
                <div dangerouslySetInnerHTML={{__html: info?.mainText}}></div>
            </div>
            <div className="chessBoard">
                {info?.chessBoard.length > 0 && (
                    info?.chessBoard.map((itm, index) => {
                        return <div key={index} dangerouslySetInnerHTML={{__html: itm?.text}}></div>
                    })
                )}
            </div>
        </div>
    )
}

export default ChessText;