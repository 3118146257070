import React, {useState} from 'react';

const Legal = ({legal, closed, title, noVersion}) => {
    const [showLegal, setShowLegal] = useState(!closed || false)

    return (
        <div className="Legal">
            <div className="tit" onClick={() => setShowLegal(!showLegal)}>
                <h3>{title || 'Here is the legal bit'}</h3>
                {showLegal ? <h4>-</h4>: <h4>+</h4>}
            </div>
            {showLegal && (
                <>
                    <div className="desc">
                        <div dangerouslySetInnerHTML={{__html: legal?.mainText}}></div>
                    </div>
                    {!noVersion && <h6>v. 1.13</h6>}
                </>
            )}
        </div>
    )
}

export default Legal